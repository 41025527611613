<template>
  <div class="row" v-if="loading">
    Loading...
  </div>
  <div class="row m-0" v-else>
    <div class="p-0 clickable text-center" :class="[isMobile ? 'col-6 confirmation-mobile' : (isTablet ? 'col-4': 'col-3')]" v-for="(c, i) of confirmations" :key="'conf_'+i" @click="getQuotePdf(c.id)">
      <div class="col-12 p-0">
        <img src="../assets/images/icon_doc.png" class="doc_icn">
      </div>
      <div class="col-12 p-0 mt-2" v-html="title(c)" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
export default {
  data () {
    return {
      loading: true
    }
  },
  created () {
    this.getConfirmations().then(() => {
      this.loading = false
    })
  },
  computed: {
    ...mapGetters([
      'confirmations',
      'isMobile',
      'isTablet'
    ])
  },
  methods: {
    ...mapActions([
      'getConfirmations',
      'getQuotePdf'
    ]),
    title (c) {
      let s
      if (c && c.confirmationNum) {
        s = c.confirmationNum.substring(0, 10)
      } else if (c && c.dateIn) {
        s = moment(c.dateIn).format('MMM YYYY')
      }
      return `${s}<br />${c.property}`
    }
  }
}
</script>
<style scoped>
.confirmation-mobile {
  font-size: smaller;
}
.confirmation-mobile img {
  height: 50px;
}
</style>
